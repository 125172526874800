<template>
  <div class="wytg">
    <ShowDetail v-if="showDetail" @closeDialog2="closeDialog2" ref="showDetail" />
    <TsDialog
      v-if="showTS"
      @closeDialog="closeDialog"
      :cropCodeArr="cropCodeArr"
      :orderObj="orderObj"
    />
    <div class="form-list">
      <div class="table-search">
        <h3>托管推送</h3>
        <!-- 方便找页面 后期可能删除 -->
        <FormQuery
          ref="formquery"
          :data="Query"
          :key="keyForm"
          @submit="search"
          @reset="reset"
        >
        </FormQuery>
      </div>
      <div class="table-content">
        <table-page
          :data="tableData"
          :columns="columns"
          :currentPage="query.pnum"
          :pageSize="query.psize"
          :total="total"
          :border="true"
          @sizeChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        >
          <template slot="orderType" slot-scope="scope">
            <span>{{
              scope.row.orderType == 1 ? "全程托管" : "环节托管"
            }}</span>
          </template>
          <template slot="Time" slot-scope="scope">

            <span>{{ scope.row.startDate }}~ <br>{{ scope.row.endDate }}</span>
          </template>
          <template slot="operation" slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="detailClick(scope.row)"
              >查看</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="push(scope.row)"
              >推送</el-button
            >
          </template>
        </table-page>
      </div>
    </div>
  </div>
</template>
      <script>
      import ShowDetail from "./showDetail";
import FormQuery from "@/components/form/form-search";
import TablePage from "@/components/table/table-page";
import TsDialog from "./TsDialog";
import { tgtsColumn } from "../tableColumn";
import { getPushList } from "@/api/add";
const Query = [
  {
    name: "cropCode",
    label: "托管种养品种：",
    componentType: "input",
    inputType: "",
    placeholder: "",
    width: "155px",
  },

  {
    name: "orderType",
    label: "托管类型：",
    componentType: "select",
    placeholder: "请选择",
    width: "150px",
    dataOptions: [
      {
        name: "全程托管",
        value: 1,
      },
      {
        name: "环节托管",
        value: 2,
      },
    ],
  },
  {
    name: "farmerName",
    label: "托管人：",
    componentType: "input",
    inputType: "",
    placeholder: "",
    width: "155px",
  },
];
export default {
  components: {
    FormQuery,
    TablePage,
    TsDialog,
    ShowDetail
  },
  filters: {
    name1Filter(value) {
      return value == 1 ? "全程托管" : "环节托管";
    },
  },
  data() {
    return {
      showDetail:false,
      orderObj:null,
      cropCodeArr: [],
      showTS: false,
      keyForm: 1, //下拉框为请求来的数据时监听会用到
      form: {},
      Query: Query,
      query: {
        pnum: 1,
        psize: 10,
      },
      total: 0,
      tableData: [],
      columns: tgtsColumn,
      tableData: [],
      options: [
        { value: 0, label: "已成交" },
        { value: 1, label: "未成交" },
      ],
    };
  },
  watch: {
    keyForm(n, o) {
      console.log("keyForm", n);
    },
  },
  created() {
    this.getList();
  },
  methods: {
    // 关闭详情
    closeDialog2(){
      this.showDetail = false;
    },
    // 详情点击
    detailClick(val){
      this.showDetail=true;
      this.$nextTick(()=>{
        this.$refs.showDetail.init(val);
      })
    },
    // 推送
    push( b) {
      console.log("推送",b);
      this.showTS = true;
      let arr = b.cropCode.split(",");
      let arr2 = [];
      arr.forEach((item) => {
        arr2.push({
          name: item,
          value: item,
        });
      });
      this.cropCodeArr = arr;
      this.orderObj=b;
    },
    // 分页事件
    handleCurrentChange(page) {
      this.query.pnum = page;
      this.getList();
    },
    // 分页数量改变
    handleSizeChange(size) {
      this.query.pnum = 1;
      this.query.psize = size;
      this.getList();
    },
    // 关闭推送
    closeDialog() {
      this.showTS = false;
    },
    // 获取列表
    async getList() {
      let res = await getPushList(this.query);
      if (!res.code) {
        this.tableData = res.data;
        this.total = res.dataNum;
      } else {
        this.$message.error("查询失败");
      }
    },
    // 条件查询
    search(params) {
      this.query = { ...this.query, ...params };
      this.getList();
    },
    // 条件重置
    reset() {
      this.query.pnum = 1;
      this.query.psize = 10;
      this.getList();
    },
  },
};
</script>
      <style lang="less" scoped>
.form-list {
  .table-search {
    padding: 0 0 0 30px;
    /deep/.el-button {
      padding: 10px 20px !important;
    }
    .tools {
      margin-bottom: 18px;
      margin-top: 6px;
    }
  }
  .table-content {
    padding: 0 30px;
    margin-top: 6px;
  }
}
</style>
